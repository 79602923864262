
import { Options, Vue } from "vue-class-component";
import GithubStar from "@/components/icons/GithubStar.vue";
import { IAllProjectData } from "@/model/project";

@Options({
  components: {
    GithubStar,
  },
  props: ["project"],
})
export default class GithubStarButton extends Vue {
  public project!: IAllProjectData;
}
