
import { Options } from "vue-class-component";
import { ProjectsService } from "@/services";
import { IAllProjectData } from "@/model/project";
import GithubProject from "@/components/github/GithubProject.vue";
import LoadingIcon from "@/components/icons/LoadingIcon.vue";
import { ScrollerComponent } from "@/components/abstract/ScrollerComponent";

@Options({
  components: {
    GithubProject,
    LoadingIcon,
  },
})
export default class Projects extends ScrollerComponent {
  public allProjects: IAllProjectData[] = [];
  public loading = true;

  public mounted(): void {
    ProjectsService.getAllProjectData().subscribe({
      next: (allProjects) => {
        this.allProjects = allProjects;
        this.handleScrolling();
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
