
import { Options, Vue } from "vue-class-component";
import AboutMe from "@/components/AboutMe.vue";
import Header from "@/components/Header.vue";

@Options({
  components: {
    AboutMe,
    Header,
  },
})
export default class Home extends Vue {}
