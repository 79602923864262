
import { Options, Vue } from "vue-class-component";
import GithubIcon from "@/components/icons/GithubIcon.vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import MailIcon from "@/components/icons/MailIcon.vue";
import LinkedinIcon from "@/components/icons/LinkedinIcon.vue";
import MenuIcon from "@/components/icons/MenuIcon.vue";
import { Mutations } from "@/store";
import AtomIcon from "@/components/icons/AtomIcon.vue";

@Options({
  components: {
    GithubIcon,
    HomeIcon,
    MailIcon,
    LinkedinIcon,
    MenuIcon,
    AtomIcon,
  },
})
export default class AboutMe extends Vue {
  menuClick(): void {
    this.$store.commit(Mutations.TOGGLE_ABOUT_ME);
  }
  onLinkClick(): void {
    this.$store.commit(Mutations.SET_SCROLL_TOP, 0);
    this.$store.commit(Mutations.TOGGLE_ABOUT_ME, false);
  }
}
