
import { IAllProjectData, IGithubLanguage } from "@/model/project";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["project"],
})
export default class GithubLanguages extends Vue {
  public project!: IAllProjectData;

  public getSortedLangs(): IGithubLanguage[] {
    return (
      this.project?.languages
        .sort((a, b) => (a.weight > b.weight ? -1 : 1))
        .filter((l) => l.percentage > 1) || []
    );
  }
}
