
import { Options } from "vue-class-component";
import LoadingIcon from "@/components/icons/LoadingIcon.vue";
import { ScrollerComponent } from "@/components/abstract/ScrollerComponent";
import { BlogService } from "@/services";
import { IBlogIntro } from "@/model/blog";

@Options({
  components: {
    LoadingIcon,
  },
})
export default class Blog extends ScrollerComponent {
  public blogIntros: IBlogIntro[] = [];

  mounted(): void {
    BlogService.getBlogIntroductions().subscribe((blogIntros) => {
      this.blogIntros = blogIntros || [];
      this.handleScrolling();
    });
  }
}
