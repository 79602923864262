<template>
  <div class="loading-icon__container">
    <div>
      <img class="mt-12" src="../../assets/loading.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-icon__container {
  display: flex;
  justify-content: center;
  height: 500px;
}
</style>
