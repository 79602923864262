
import { IAllProjectData } from "@/model/project";
import { Options, Vue } from "vue-class-component";
import GithubStarButton from "@/components/github/GithubStarButton.vue";
import GithubLanguages from "@/components/github/GithubLanguages.vue";

@Options({
  props: ["project", "disable-link"],
  components: {
    GithubStarButton,
    GithubLanguages,
  },
})
export default class GithubProject extends Vue {
  public project!: IAllProjectData;
  public disableLink!: boolean;
}
