import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6e4b5f00")
const _hoisted_1 = { class: "lang-bar mb-2" }
const _hoisted_2 = { class: "-ml-4 flex flex-wrap font-normal" }
const _hoisted_3 = { class: "flex items-center justify-center mr-1" }
const _hoisted_4 = { class: "font-light text-sm" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSortedLangs(), (language, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "lang-bar__item",
          key: index,
          style: _normalizeStyle({
        width: language.percentage + '%',
        backgroundColor: language.color,
      })
        }, null, 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.languages, (language, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ml-4 flex items-center",
          key: index
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "lang-dot",
              style: _normalizeStyle({ background: language.color })
            }, null, 4)
          ]),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(language.name) + " ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(language.percentage) + "%", 1)
          ])
        ]))
      }), 128))
    ])
  ], 64))
}